import { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import airPollutantsDescriptions from './../airPollutantsDescriptions.json';
import { LatLngExpression } from 'leaflet';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from 'leaflet';
import { isMobile } from 'react-device-detect';
interface DetailsProps {
	parameter: any,
	userLocation: string,
	onClose: any
}
const Details = ({ parameter, userLocation, onClose }: DetailsProps) => {
  const userCoordinates = [parseFloat(userLocation.split(',')[0]), parseFloat(userLocation.split(',')[1])] as LatLngExpression;
  const parameterCoordinates = [parseFloat(parameter.coordinates.split(',')[0]), parseFloat(parameter.coordinates.split(',')[1])] as LatLngExpression;

  // State to store the pollutant description
  const [pollutantDescription, setPollutantDescription] = useState('');

  useEffect(() => {
    // Search for a matching pollutant name and get the description
    const normalizedParameterName = normalizeDisplayName(parameter.parameter).toLowerCase();
    const matchingPollutant = airPollutantsDescriptions.airPollutants.find(pollutant =>
      normalizeDisplayName(pollutant.name).toLowerCase() === normalizedParameterName
    );
    if (matchingPollutant) {
      setPollutantDescription(matchingPollutant.description);
    } else {
      setPollutantDescription('Description not available');
    }
  }, [parameter.parameter]);

  // Helper function to normalize pollutant names
  const normalizeDisplayName = (displayName: string) => {
    return displayName
      .replace('²', '2')
      .replace('₃', '3')
      .replace('³', '3')
      .replace('₂', '2')
      .replace('µ', 'u');
  };
  return (
    <div className="app-popup-container">
      <div className="app-popup">
        <h2>{parameter.location}</h2>
        <p>{parameter.distance} miles away</p>
        <div className="left">
          <ul>
            <li>
              <strong>Air pollutant:</strong> {parameter.parameter}
            </li>
            <li>
              <strong>Value:</strong> {parameter.lastValue} {parameter.paramUnit}
            </li>
            <li>
              <strong>Safety:</strong> {parameter.safety}
            </li>
            <li>
              <strong>Last Updated:</strong> {parameter.lastUpdated}
            </li>
          </ul>

          {isMobile ? <div className="text-sm justify">{pollutantDescription}</div> : <div className="justify">{pollutantDescription}</div>}
        </div>
        <div className="map-container">
          <MapContainer center={userCoordinates} zoom={9} style={{ height: '250px', width: '500px' }}>
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={userCoordinates} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
              <Popup>You are here</Popup>
            </Marker>
            <Marker position={parameterCoordinates} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
              <Popup>{parameter.location}</Popup>
            </Marker>
	    <Polyline positions={[userCoordinates, parameterCoordinates]} color="blue" />
          </MapContainer>
        </div>
        <button className="" onClick={onClose}>
          Close
        </button>
      </div>
    </div>
  );
};

export default Details;
