import logo from '../assets/logo.png';
import '../App.css';
import { useState, useEffect } from 'react';

function SplashScreen() {
  const [delayMsg, setDelayMsg] = useState("");
  useEffect(() => {
	const data = localStorage.getItem("splashScreenDelayMsg");
  	if (data) {
		const obj: any = JSON.parse(data);
		setDelayMsg(obj.msg);
	}
  }, [delayMsg]);
  return (
    <div className="splash-screen">
      <img src={logo} alt="CleanAirTracker Logo" className="logo" />
      <p>Loading...</p>
      <br />
      {
	delayMsg !== "" ? <p>{delayMsg}</p> : <p></p>
      }
    </div>
  );
}

export default SplashScreen;
