import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import { LatLngExpression } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { ParametersInfoObj } from '../App';
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Icon } from 'leaflet';
interface MapAllProps {
	apiResponse: ParametersInfoObj[],
	userLocation: string
}
const MapAll = ({ apiResponse, userLocation }: MapAllProps) => {
  const userCoordinates = [parseFloat(userLocation.split(',')[0]), parseFloat(userLocation.split(',')[1])] as LatLngExpression;

  // Create an array of coordinates for drawing the lines
  const linesCoordinates = apiResponse.map((parametersInfoObj: ParametersInfoObj) => (
    parametersInfoObj.parameters.map((parameter: any) => (
      [userCoordinates, [parseFloat(parameter.coordinates.split(',')[0]), parseFloat(parameter.coordinates.split(',')[1])]]
    ))
  )).flat(); // Flatten the array of arrays

  return (
    <div className="map-container">
      <MapContainer center={userCoordinates} zoom={9} style={{ height: '500px', width: '100%' }}>
       <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" /> 
	<Marker position={userCoordinates} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
          <Popup>You are here</Popup>
        </Marker>
        {apiResponse.map((parametersInfoObj: any, index: any) => (
          parametersInfoObj.parameters.map((parameter: any, paramIndex: any) => {
            const parameterCoordinates: any = [parseFloat(parameter.coordinates.split(',')[0]), parseFloat(parameter.coordinates.split(',')[1])];
            return (
              <Marker key={`${index}-${paramIndex}`} position={parameterCoordinates} icon={new Icon({iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41]})}>
                <Popup>{parameter.location}</Popup>
              </Marker>
            );
          })
        ))}
        {linesCoordinates.map((coordinates: any, index: any) => (
          <Polyline key={index} positions={coordinates} color="blue" />
        ))}
      </MapContainer>
    </div>
  );
};

export default MapAll;
