import { useState, useEffect } from 'react';
import airPollutantsData from './airPollutantsData.json';
import SplashScreen from './Components/SplashScreen';
import MedicalPopup from './Components/MedicalPopup';
import MapAll from './Components/MapAll';
import Details from './Components/Details';
import './App.css';
export interface LocationData {
  name: string;
  coordinates: {
    latitude: number;
    longitude: number;
  };
  parameters: Parameter[];
}

export interface Parameter {
  displayName: string;
  lastValue: number;
  parameter: string;
  unit: string;
  lastUpdated: string;
}

export interface Threshold {
  Safe: { true: number; false: number };
  Unsafe: { true: number; false: number };
  Dangerous: { true: number; false: number };
}

export interface Pollutant {
  displayName: string;
  threshold: Threshold;
}

export interface ParametersInfo {
  location: string;
  distance: string;
  parameter: string;
  lastValue: number;
  paramUnit: string;
  safety: string;
  lastUpdated: string;
  coordinates: string;
}

export interface ParametersInfoObj {
  parameters: ParametersInfo[];
}

function App() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<string>('');
  const [cityName, setCityName] = useState<string>('');
  const [apiResponse, setApiResponse] = useState<ParametersInfoObj[]>([]);
  const [aqi, setAqi] = useState(null);
  const [stationName, setStationName] = useState<string>('');
  const [clickedRow, setClickedRow] = useState<number>(-1);
  const [clickedColumn, setClickedColumn] = useState<number>(0);

  const medicalCondition = localStorage.getItem('medicalCondition') === 'true';
  function success(pos: GeolocationPosition) {
  	console.log("success");
  }	
  function error(err: GeolocationPositionError) {
  	console.warn(`ERROR(${err.code}): ${err.message}`);
	const delayMsgObj = {
		msg: "Sorry, we're taking a bit longer than usual to load..."	
	};
  	localStorage.setItem("splashScreenDelayMsg", JSON.stringify(delayMsgObj));
  }
  // Function to get and store the user's location
  function getLocation() {
    if (navigator.geolocation) {
	const options = {
  		enableHighAccuracy: true,
  		timeout: 5000,
  		maximumAge: 0,
	};

	navigator.geolocation.getCurrentPosition(success, error, options);
        
        navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {	       
	       const lat = position.coords.latitude;
               const lon = position.coords.longitude;
               const locationString = `${lat.toFixed(6)},${lon.toFixed(6)}`;
               setLocation(locationString);
               // Fetch city name using the geocoding API
               fetch(`https://geocode.maps.co/reverse?lat=${lat}&lon=${lon}&api_key=65bc5a89ad121818272659xwy3189ca`)
               		.then((response) => response.json())
               		.then((data) => {
	        		let city = data.address?.city;
	    			if (!city) {
					city = data.address?.county || 'Unknown';		
	    			}
            			setCityName(city);
               		})
               		.catch((error) => {
            			console.error('Error fetching city name:', error);
        		})
          		.finally(() => {
            			setIsLoading(false); // Set loading to false when done
          		});
        });
    } else {
  	setLocation("Geolocation is not supported by this browser.");
    }
  }
  
  // Custom sorting function to sort parameter names correctly
  function customSort(a: { parameter: string }, b: { parameter: string }) {
    const nameA = a.parameter.toLowerCase();
    const nameB = b.parameter.toLowerCase();
  
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  
    // If the names are the same, compare numerically
    const numA = parseInt((a.parameter.match(/\d+/) || '0').toString(), 10);
    const numB = parseInt((b.parameter.match(/\d+/) || '0').toString(), 10);

    return numA - numB;
  }  

  // Function to calculate the Haversine distance between two coordinates
  function calculateDistance(lat1: number, lon1: number, lat2: number, lon2: number) {
    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515; // Miles
    return dist;
  }

  // Function to format timestamp as "hours/minutes ago"
  function formatTimeAgo(timestamp: string) {
    const now = new Date();
    const updatedTime = new Date(timestamp);
    const diff = now.getTime() - updatedTime.getTime();
    const hours = Math.floor(diff / 3600000); // 1 hour = 3600000 milliseconds
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    const minutes = Math.floor(diff / 60000); // 1 minute = 60000 milliseconds
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }

  // Function to get safety level based on lastValue, parameter, and medicalCondition
  function getSafetyLevel(displayName: string, lastValue: number, medicalCondition: boolean): string {
    // Replace special characters in displayName and normalize it
    const normalizedDisplayNameJSON = normalizeDisplayName(displayName);

    const pollutant = airPollutantsData.airPollutants.find(
      (item) =>
        normalizeDisplayName(item.displayName).toLowerCase() ===
        normalizedDisplayNameJSON.toLowerCase()
    );

    if (pollutant) {
      const thresholds = pollutant.threshold;
      const medicalKey = medicalCondition ? 'true' : 'false';
      const safetyThreshold = thresholds.Safe[medicalKey];
      const unsafeThreshold = thresholds.Unsafe[medicalKey];

      if (lastValue <= safetyThreshold) {
        return 'Safe';
      } else if (lastValue <= unsafeThreshold) {
        return 'Unsafe';
      } else {
        return 'Dangerous';
      }
    }

    return 'Unknown'; // Default to 'Unknown' if the pollutant is not found
  }
  
  // Function to normalize a displayName
  function normalizeDisplayName(displayName: string) {
    return displayName
      .replace('²', '2')
      .replace('₃', '3')
      .replace('³', '3')
      .replace('₂', '2')
      .replace('µ', 'u');
  }  

  // Function to make the API request
  function makeApiRequest(location: string) {
    const apiUrl = `https://api.openaq.org/v2/locations?limit=100&page=1&offset=0&sort=desc&coordinates=${encodeURIComponent(
      location
    )}&radius=25000&order_by=distance&dump_raw=false`;
  
    fetch(apiUrl, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Host: 'api.openaq.org',
      },
    })
      .then((response: Response) => response.json())
      .then((data: { results: LocationData[] }) => {
        if (data.results && data.results.length > 0) {
          const parametersSeen = new Set<string>();
          const parametersInfo: ParametersInfoObj = {
            parameters: [],
          };
          const twoDaysAgo = new Date();
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);

          data.results.forEach((locationData: LocationData) => {
            const name = locationData.name;
            const sensorCoordinates = locationData.coordinates;
            const sensorDistance = calculateDistance(
              parseFloat(location.split(',')[0]),
              parseFloat(location.split(',')[1]),
              sensorCoordinates.latitude,
              sensorCoordinates.longitude
            );
	    const sortedParameters = locationData.parameters
              .filter((param: Parameter) => {
                const normalizedDisplayNameJSON = normalizeDisplayName(param.displayName);
		return (
                  !parametersSeen.has(param.parameter) &&
                  new Date(param.lastUpdated) > twoDaysAgo &&
                  airPollutantsData.airPollutants.some((item: Pollutant) =>
                    normalizeDisplayName(item.displayName).toLowerCase() ===
                    normalizedDisplayNameJSON.toLowerCase()
                  ) &&
		  param.lastValue > 0
                );
              })
              .sort(customSort);
              sortedParameters.forEach((param: Parameter) => {
                const timeAgo = formatTimeAgo(param.lastUpdated);
                const safetyLevel = getSafetyLevel(param.displayName, param.lastValue, medicalCondition);
                console.log(param.parameter);
                parametersInfo.parameters.push({
                  location: name,
                  distance: sensorDistance.toFixed(2),
                  parameter: param.parameter,
                  lastValue: param.lastValue,
                  paramUnit: param.unit,
                  safety: safetyLevel,
                  lastUpdated: timeAgo,
                  coordinates: sensorCoordinates.latitude + ',' + sensorCoordinates.longitude
                });
            
                parametersSeen.add(param.parameter);
              });            
          });
          if (apiResponse) {
            setApiResponse((prev: ParametersInfoObj[]) => [...prev, parametersInfo]);
          }
        } else {
          setApiResponse([]);
        }
      }).catch((error: Error) => {
        const parametersInfo: ParametersInfoObj = {
          parameters: [
            {
              location: 'Error',
              distance: '0.00',
              parameter: 'Error',
              lastValue: 0,
              paramUnit: '',
              safety: 'Error: ' + error.message,
              lastUpdated: 'N/A',
              coordinates: '0,0'
            },
          ],
        };
        setApiResponse([parametersInfo]);
      });      
  }

  function fetchAQI(location: string) {
    const [lat, lng] = location.split(',').map(parseFloat);
    const base_url = 'https://api.waqi.info';
    const token = '169cafbe35ac0fe8850d6598f69b7e56a9afdaeb'; // Replace with your actual API key
    const apiUrl = `${base_url}/feed/geo:${lat};${lng}/?token=${token}`;
  
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.data) {
          const aqiValue = data.data.aqi;
          setAqi(aqiValue); // Update the aqi state with the fetched value
  
          // Set stationName from the fetched data
          const station = data.data.city.name || 'Unknown';
          setStationName(station);
        }
      })
      .catch((error) => {
        console.error('Error fetching AQI:', error);
      });
  }  

  // Use useEffect to call getLocation and makeApiRequest when the component mounts
  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (location) {
      makeApiRequest(location);
    }
  }, [location]);
  
  useEffect(() => {
    if (location) {
      fetchAQI(location);
    }
  }, [location]);
  
  return (
    <div className="App">
      {isLoading ? (
        <SplashScreen />
      ) : (
        <>
          <MedicalPopup />
          <h1 className="white-header">{cityName}</h1>
          <div className="data-rows">
            <div className="data-group">
              {aqi !== null && (
                <div className={`aqi data-row ${getSafetyLevel("aqi", aqi, medicalCondition).toLowerCase()}`}>
                  <div className="aqi-container">
                    Air Quality Index: {aqi} ({getSafetyLevel("aqi", aqi, medicalCondition)})
                  </div>
                  {stationName && (
                    <div className="station-info">
                      Station: {stationName}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="data-rows">
            {apiResponse.map((parametersInfoObj, index) => (
              <div className="data-group" key={index}>
                {parametersInfoObj.parameters.map((parameter, paramIndex) => (
                  <div
                    className={`data-row ${parameter.safety.toLowerCase()} ${
                      clickedRow === index && clickedColumn === paramIndex ? 'active' : ''
                    }`}
                    key={paramIndex}
                    onClick={() => {
                      // Handle row click and update clickedRow and clickedColumn state
                      setClickedRow(index === clickedRow && paramIndex === clickedColumn ? -1 : index);
                      setClickedColumn(paramIndex);
                    }}
                  >                 
                    <div className="left">
                      <p className="parameter">{parameter.parameter}</p>
                      <div className="value">
                        {parameter.lastValue.toFixed(3)} {parameter.paramUnit}
                      </div>
                      <p className="small-text">
                        {parameter.location} ({parameter.distance} miles away)
                      </p>
                    </div>
                    <div className="right">
                      <p className="safety">{parameter.safety}</p>
                      <p className="small-text">Last Updated: {parameter.lastUpdated}</p>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
          
          <div>
              <MapAll apiResponse={apiResponse} userLocation={location} />
          </div>

          {clickedRow !== -1 && (
            <div className="overlay">
              <Details
                parameter={apiResponse[clickedRow].parameters[clickedColumn]} // Use clickedColumn
                userLocation={location}
                onClose={() => {
                  setClickedRow(-1);
                  setClickedColumn(0); // Reset clickedColumn when closing the overlay
                }}
              />
            </div>          
          )}

          <div className="copyright">
            &copy; {new Date().getFullYear()} Toby Nguyen, Ram Patel. All rights reserved.
          </div>

        </>
      )}
    </div>
  );
}

export default App;
