import React, { useState, useEffect } from 'react';

export const MedicalPopup = () => {
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    const isClicked = localStorage.getItem('isClicked') === 'true';
    setClicked(isClicked);
  }, []);

  function handleYes(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    localStorage.setItem('isClicked', 'true');
    setClicked(true);
    localStorage.setItem('medicalCondition', 'true');
    window.location.reload();
  }
  
  function handleNo(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    localStorage.setItem('isClicked', 'true');
    setClicked(true);
    localStorage.setItem('medicalCondition', 'false');
  }

  if (clicked) {
    return null; // Popup is hidden once the user has made a choice
  }

  return (
    <div className="app-popup-container">
      <div className="app-popup">
        <p>Do you have a medical condition that is associated with respiratory issues?</p>
        <button onClick={handleYes}>Yes</button>
        <button onClick={handleNo}>No</button>
      </div>
    </div>
  );
};

export default MedicalPopup;
